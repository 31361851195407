import { PaymentMethod } from '../lib/common-interfaces';

export const payOnline: PaymentMethod = {
  _id: 'payonline',
  name: 'payonline',
  icon: 'credit-card',
};

export enum ShippingTypes {
  TYPE_BASIC = 'basic',
  TYPE_FAST = 'fast',
  TYPE_EXPRESS = 'express',
  TYPE_SUPER_EXPRESS = 'super_express',
}
