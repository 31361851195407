import * as React from 'react';
import { graphql } from 'gatsby';
import { Row, Col } from 'antd';
import Page from '../components/Page/Page';
import IndexLayout from '../layouts';
import GQL from '../graphql-types';
import { CartTotals } from '../components/CartTotals/CartTotals';
import { CheckoutForm } from '../components/CheckoutForm/CheckoutForm';

export interface CartItem {
  product: GQL.ContentfulProductEdge;
  quantity: number;
}

interface CheckoutData {
  products: GQL.ContentfulProductConnection;
}

export interface CheckoutProps {
  data: CheckoutData;
}

const CheckoutPage = ({ data }: CheckoutProps) => {
  const products = data.products.edges.map(p => p.node);

  const rowStyle: React.CSSProperties = {
    maxWidth: '1140px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '40px',
    marginBottom: '40px',
  };

  return (
    <IndexLayout>
      <Page>
        <Row style={rowStyle}>
          <Col lg={16} md={24}>
            <CheckoutForm products={products} />
          </Col>
          <Col lg={8} md={24}>
            <CartTotals page="checkout" />
          </Col>
        </Row>
      </Page>
    </IndexLayout>
  );
};

export default CheckoutPage;

export const pageQuery = graphql`
  query($lang: String) {
    products: allContentfulProduct(filter: { node_locale: { eq: $lang } }) {
      edges {
        node {
          ...ProductFragment
        }
      }
    }
  }
`;
