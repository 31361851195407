import { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Location, WindowLocation } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { Form, Input, Card, Steps, Select, Radio, Icon, Spin } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { parsePhoneNumberFromString, CountryCode, getCountryCallingCode } from 'libphonenumber-js';
import * as ISOCountries from 'i18n-iso-countries';
import { RadioChangeEvent } from 'antd/lib/radio';
import debounce from 'lodash/debounce';
import { LabeledValue } from 'antd/lib/select';
import * as React from 'react';
import GQL from '../../graphql-types';
import i18n from '../../i18n';
import { usePrevious } from '../../lib/hooks/usePrevious';
import {
  CreateOrderActionCreator,
  ReadOrderActionCreator,
  SetManagerActionCreator,
  SaveOrderFieldsActionCreator,
  SaveOrderFieldsAction,
  ClearOrderActionCreator,
} from '../../redux/order/order.actions';
import { Order, ShippingMethod, CartItem } from '../../lib/common-interfaces';
import { RootModel } from '../../redux/store';
import css from './checkoutform.module.scss';
import { AddToCartSagaActionCreator, ClearCartActionCreator } from '../../redux/cart/cart.actions';
import { payOnline, ShippingTypes } from '../../constants';
import { getShippingMethodsActionCreator, setSelectedShippingActionCreator } from '../../redux/shipping/shipping.actions';

// eslint-disable-next-line @typescript-eslint/no-var-requires
ISOCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));
// eslint-disable-next-line @typescript-eslint/no-var-requires
ISOCountries.registerLocale(require('i18n-iso-countries/langs/ru.json'));

export enum StepStatus {
  wait = 'wait',
  process = 'process',
  finish = 'finish',
  error = 'error',
}

const initialStatus = {
  0: StepStatus.wait,
  1: StepStatus.wait,
  2: StepStatus.wait,
};

interface City {
  countryCode: string;
  latitude: string;
  longitude: string;
  name: string;
  stateCode: string;
}

export interface CheckoutFormComponentProps extends FormComponentProps {
  products: GQL.ContentfulProduct[];
}

interface City {
  key: string;
  value: string;
}

type CitiesArray = Array<City>;

const CheckoutFormComponent = (props: CheckoutFormComponentProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { loading, cart, order, savedOrder, shippingMethods, shippingLoading, shippingSelected, countryCode, manager } = useSelector(
    (state: RootModel) => ({
      loading: state.order.status.loading,
      cart: state.cart.cart,
      order: state.order,
      savedOrder: state.order.savedOrder,
      shippingMethods: state.shipping.methods,
      shippingLoading: state.shipping.status.loading,
      shippingSelected: state.shipping.selected,
      countryCode: state.geocode.countryCode,
      manager: state.order.manager,
    })
  );

  const [location, updateLocation] = useState<WindowLocation | undefined>();
  const prevLocation = usePrevious(location);

  const [isLoadingCity, setIsLoadingCity] = useState<boolean>(false);
  const [isCountryShiptor, setIsCountryShiptor] = useState<boolean>(false);
  const [isVertical] = useState<boolean>(true);
  const [phonePrefix, updatePhonePrefix] = useState<string>('    ');
  const [currentStep, updateCurrentStep] = useState<number>(0);
  const [stepStatus, updateStepStatus] = useState(initialStatus);
  const [options, setOptions] = useState<CitiesArray | null>(null);

  const { form, products } = props;
  const { getFieldDecorator, validateFields } = form;
  const { Step } = Steps;
  const { Option } = Select;
  const prefix = '+';

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 7 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 17 },
    },
  };
  const maxStep = 2;
  const prevStep = usePrevious(currentStep);

  const validateStep = (step: number) => {
    let fieldSet: string[] = [];
    switch (step) {
      case 0:
        fieldSet = ['firstName', 'lastName', 'email', 'country', 'address', 'postalCode', 'phone'];
        break;
      case 1:
        fieldSet = ['shippingMethod'];
        break;
      case 2:
        fieldSet = ['paymentMethod'];
        break;
      default:
        break;
    }
    setTimeout(() => {
      validateFields(fieldSet, (errors, values) => {
        let status: StepStatus;
        if (!errors && values) {
          status = StepStatus.finish;
          if (step <= maxStep) updateCurrentStep(step + 1);
        } else {
          status = StepStatus.error;
        }
        updateStepStatus(prevState => {
          return { ...prevState, [step]: status };
        });
      });
    }, 0);
  };

  useEffect(() => {
    if (prevStep !== undefined) {
      validateStep(prevStep);
      validateStep(currentStep);
    }
  }, [currentStep]);

  const validatePhone = (_: any, value: any, callback: any) => {
    if (value && phonePrefix) {
      const countryName = form.getFieldValue('country');
      const country = ISOCountries.getAlpha2Code(countryName, i18n.language) || navigator.language;
      const phoneNumber = parsePhoneNumberFromString(`${phonePrefix}${value}`, country as CountryCode);
      if (phoneNumber && phoneNumber.isValid()) {
        updatePhonePrefix(prefix.concat(phoneNumber.countryCallingCode as string));
        callback();
      } else {
        callback(`${t('checkout:validatePhone.error')} ${countryName}`);
      }
    } else {
      callback();
    }
  };

  const getShippingMethods = (country: string, city = ' ', kladrId: string) => {
    if (country) {
      dispatch(getShippingMethodsActionCreator({ items: cart, country, city, kladrId }));
    }
  };

  const getIcon = (shippingType: ShippingTypes): JSX.Element => {
    let iconType: string;
    switch (shippingType) {
      case ShippingTypes.TYPE_BASIC:
        iconType = 'compass';
        break;
      case ShippingTypes.TYPE_FAST:
        iconType = 'dashboard';
        break;
      case ShippingTypes.TYPE_EXPRESS:
        iconType = 'fire';
        break;
      case ShippingTypes.TYPE_SUPER_EXPRESS:
        iconType = 'rocket';
        break;
      default:
        iconType = 'compass';
    }
    return <Icon type={iconType} className={css.icon} />;
  };

  const getPhonePrefix = (country: string) => {
    updatePhonePrefix(prefix.concat(getCountryCallingCode(country as CountryCode).toString()));
  };

  const onCountrySelect = () => {
    form.setFieldsValue({
      city: '',
    });
    setOptions(null);
    const country = ISOCountries.getAlpha2Code(form.getFieldValue('country'), i18n.language);
    getPhonePrefix(country);
    setIsCountryShiptor(country === 'RU');
    if (country !== 'RU') {
      setStep(0);
      getShippingMethods(country, '', '');
    }
    validateStep(0);
  };

  const onCitySelect = (value: LabeledValue) => {
    form.setFieldsValue({ city: value });
    setStep(0);
    getShippingMethods(order.savedFields?.user?.countryCode || countryCode || '', value.label as string, value.key);
  };

  const onCityChange = (e: any) => {
    e.persist();
    form.setFieldsValue({ city: e.target.value });
    validateStep(0);
  };

  const fetchCities = (city: string) => {
    if (!city) return;
    setIsLoadingCity(true);

    const prefixEnd = Math.max(city.indexOf(' '), city.indexOf('.')) + 1;
    let name = city.slice(prefixEnd < city.length ? prefixEnd : 0);

    if (name.length < 3) return;
    name = name.charAt(0).toUpperCase() + name.slice(1); // capitalize first letter

    fetch(`${process.env.PAYTEST_GOSLIM_PRO_URL}/cities?city=${name}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(res => setOptions(res.map((item: { kladrId: string; name: string }) => ({ key: item.kladrId, value: item.name }))))
      .finally(() => {
        setIsLoadingCity(false);
      });
  };

  const validateAndSubmitForm = () => {
    if (Object.values(stepStatus).every(status => status === StepStatus.finish)) {
      form.validateFields((errors, values) => {
        if (!errors) {
          const orderToSubmit: Order = {
            user: {
              firstName: values.firstName,
              lastName: values.lastName,
              phone: `${phonePrefix}${values.phone}`,
              email: values.email,
              country: values.country,
              countryCode: ISOCountries.getAlpha2Code(values.country, i18n.language),
              city: values.city.label ? values.city.label : values.city,
              kladrId: values.city.key,
              address: values.address,
              postalCode: values.postalCode,
            },
            products: cart,
            shippingMethod: shippingSelected as ShippingMethod,
            paymentMethod: { name: t(`checkout:payment.${payOnline.name}`), _id: payOnline._id, icon: payOnline.icon }, // hardcoded until new payment methods added
            manager,
          };
          dispatch(CreateOrderActionCreator(orderToSubmit));
        }
      });
    }
  };

  useEffect(() => {
    if (Object.values(stepStatus).every(status => status === StepStatus.finish)) {
      validateAndSubmitForm();
    }
  }, [stepStatus]);

  const setStep = (step: number) => {
    if (order.order) dispatch(ClearOrderActionCreator());
    updateStepStatus(prevState => {
      return { ...prevState, [step]: StepStatus.process };
    });

    updateCurrentStep(step);
  };

  const countries = Object.values(ISOCountries.getNames(i18n.language));

  const handleParams = (l: WindowLocation) => {
    if (l.search) {
      const url = new URL(l.href);
      const hashKey = url.searchParams.get('key');
      if (hashKey) {
        dispatch(ReadOrderActionCreator({ key: hashKey }));
      }
    }
  };

  const getDeliveryMessage = () => {
    if (shippingMethods?.length) return '';

    if (shippingLoading) {
      return t('checkout:shipping.loading');
    }

    const isCountry = !!form.getFieldValue('country');
    const isCity = !!form.getFieldValue('city');

    if (isCountry && isCity) {
      return t('checkout:shipping.notAvailableCountryAndCity');
    }

    return isCountry ? t('checkout:shipping.selectCity') : t('checkout:shipping.selectCountryAndCity');
  };

  useEffect(() => {
    if (location && location !== prevLocation) {
      handleParams(location as WindowLocation);
    }
  }, [location]);

  useEffect(() => {
    if (savedOrder && savedOrder.user) {
      // Populate form with data
      form.setFieldsValue({
        firstName: savedOrder.user.first_name,
        lastName: savedOrder.user.last_name,
        email: savedOrder.user.email,
        country: ISOCountries.getName(savedOrder.user.country, i18n.language),
        city: savedOrder.user.city,
        kladrId: savedOrder.user.kladrId,
        address: savedOrder.user.address,
        postalCode: savedOrder.user.zip,
        phone: savedOrder.user.phone,
      });
      if (savedOrder.user.phone) {
        const phoneNumber = parsePhoneNumberFromString(savedOrder.user.phone, savedOrder.user.country as CountryCode);
        if (phoneNumber) updatePhonePrefix(prefix.concat(phoneNumber.countryCallingCode as string));
      }
      // Populate cart with products
      dispatch(ClearCartActionCreator());
      savedOrder.products.forEach(savedProduct => {
        products.forEach(product => {
          if (product.contentful_id === savedProduct.id) {
            dispatch(
              AddToCartSagaActionCreator({
                product: {
                  product,
                  quantity: savedProduct.quantity,
                  option: savedProduct.option,
                  colorOption: savedProduct.colorOption,
                  discount: savedProduct.discount,
                },
                showNotification: false,
              })
            );
          }
        });
      });
      // Get available shipping methods
      getShippingMethods(savedOrder.user.country, savedOrder.user.city, '');
      if (savedOrder.manager) dispatch(SetManagerActionCreator(savedOrder.manager));
    }
  }, [savedOrder]);

  useEffect(() => {
    if (countryCode) {
      setIsCountryShiptor(countryCode === 'RU');
      form.setFieldsValue({ country: ISOCountries.getName(countryCode, i18n.language) });
      getPhonePrefix(countryCode);
    }
  }, [countryCode]);

  useEffect(() => {
    dispatch(ClearOrderActionCreator());
  }, []);

  return (
    <Location>
      {({ location: loc }) => {
        if (location !== loc) {
          updateLocation(loc);
        }
        return (
          <Spin spinning={loading} indicator={<Icon type="loading" style={{ fontSize: '48px' }} />}>
            <Form {...formItemLayout} hideRequiredMark>
              <Steps direction={isVertical ? 'vertical' : 'horizontal'} current={currentStep}>
                <Step
                  title={t('checkout:form.step1.title')}
                  status={stepStatus[0]}
                  description={
                    <div>
                      <Card className={css.card}>
                        <Form.Item label={t('checkout:form.step1.fields.firstName.label')}>
                          {getFieldDecorator('firstName', {
                            rules: [
                              {
                                required: true,
                                message: t('checkout:form.step1.fields.firstName.messages.isRequired'),
                              },
                              {
                                min: 3,
                                message: t('checkout:form.step1.fields.firstName.messages.tooShort'),
                              },
                              {
                                max: 30,
                                message: t('checkout:form.step1.fields.firstName.messages.tooLong'),
                              },
                            ],
                          })(
                            <Input
                              disabled={loading}
                              placeholder={t('checkout:form.step1.fields.firstName.placeholder')}
                              onFocus={() => setStep(0)}
                              onInput={() => validateStep(0)}
                            />
                          )}
                        </Form.Item>
                        <Form.Item label={t('checkout:form.step1.fields.lastName.label')}>
                          {getFieldDecorator('lastName', {
                            rules: [
                              {
                                required: true,
                                message: t('checkout:form.step1.fields.lastName.messages.isRequired'),
                              },
                              {
                                min: 3,
                                message: t('checkout:form.step1.fields.lastName.messages.tooShort'),
                              },
                              {
                                max: 30,
                                message: t('checkout:form.step1.fields.lastName.messages.tooLong'),
                              },
                            ],
                          })(
                            <Input
                              disabled={loading}
                              placeholder={t('checkout:form.step1.fields.lastName.placeholder')}
                              onFocus={() => setStep(0)}
                              onInput={() => validateStep(0)}
                            />
                          )}
                        </Form.Item>
                        <Form.Item label={t('checkout:form.step1.fields.email.label')}>
                          {getFieldDecorator('email', {
                            rules: [
                              {
                                required: true,
                                message: t('checkout:form.step1.fields.email.messages.isRequired'),
                              },
                              {
                                type: 'email',
                                message: t('checkout:form.step1.fields.email.messages.notValid'),
                              },
                            ],
                          })(
                            <Input
                              disabled={loading}
                              placeholder={t('checkout:form.step1.fields.email.placeholder')}
                              onFocus={() => setStep(0)}
                              onInput={() => validateStep(0)}
                            />
                          )}
                        </Form.Item>
                        <Form.Item label={t('checkout:form.step1.fields.country.label')}>
                          {getFieldDecorator('country', {
                            rules: [
                              {
                                required: true,
                                message: t('checkout:form.step1.fields.country.messages.isRequired'),
                              },
                            ],
                          })(
                            <Select
                              showSearch
                              disabled={loading}
                              placeholder={t('checkout:form.step1.fields.country.placeholder')}
                              onSelect={onCountrySelect}
                              onFocus={() => setStep(0)}
                            >
                              {countries.map(country => (
                                <Option value={country} key={country}>
                                  {country}
                                </Option>
                              ))}
                            </Select>
                          )}
                        </Form.Item>
                        <Form.Item label={t('checkout:form.step1.fields.city.label')}>
                          {getFieldDecorator('city', {
                            rules: [
                              {
                                required: true,
                                message: t('checkout:form.step1.fields.city.messages.isRequired'),
                              },
                            ],
                          })(
                            <>
                              {isCountryShiptor ? (
                                <Select
                                  disabled={loading}
                                  style={{ width: '100%' }}
                                  onSelect={onCitySelect}
                                  onSearch={debounce(fetchCities, 200)}
                                  showSearch
                                  labelInValue
                                  optionFilterProp="children"
                                  loading={isLoadingCity}
                                  notFoundContent={isLoadingCity ? <Spin size="small" /> : null}
                                >
                                  {options
                                    ? options.map((option, index) => (
                                      <Option value={option.key} key={index}>
                                        {option.value}
                                      </Option>
                                    ))
                                    : null}
                                </Select>
                              ) : (
                                <Input
                                  disabled={loading}
                                  placeholder={t('checkout:form.step1.fields.city.placeholder')}
                                  onFocus={() => setStep(0)}
                                  onChange={onCityChange}
                                />
                              )}
                            </>
                          )}
                        </Form.Item>
                        <Form.Item label={t('checkout:form.step1.fields.address.label')}>
                          {getFieldDecorator('address', {
                            rules: [
                              {
                                required: true,
                                message: t('checkout:form.step1.fields.address.messages.isRequired'),
                              },
                            ],
                          })(
                            <Input
                              disabled={loading}
                              placeholder={t('checkout:form.step1.fields.address.placeholder')}
                              onFocus={() => setStep(0)}
                              onInput={() => validateStep(0)}
                            />
                          )}
                        </Form.Item>
                        <Form.Item label={t('checkout:form.step1.fields.postalCode.label')}>
                          {getFieldDecorator('postalCode', {
                            rules: [
                              {
                                required: true,
                                message: t('checkout:form.step1.fields.postalCode.messages.isRequired'),
                              },
                              {
                                min: 3,
                                message: t('checkout:form.step1.fields.postalCode.messages.tooShort'),
                              },
                              {
                                max: 10,
                                message: t('checkout:form.step1.fields.postalCode.messages.tooLong'),
                              },
                            ],
                          })(
                            <Input
                              disabled={loading}
                              placeholder={t('checkout:form.step1.fields.postalCode.placeholder')}
                              onFocus={() => setStep(0)}
                              onInput={() => validateStep(0)}
                            />
                          )}
                        </Form.Item>
                        <Form.Item label={t('checkout:form.step1.fields.phone.label')}>
                          {getFieldDecorator('phone', {
                            rules: [
                              {
                                required: true,
                                message: t('checkout:form.step1.fields.phone.messages.isRequired'),
                              },
                              {
                                validator: validatePhone,
                              },
                            ],
                          })(
                            <Input
                              disabled={loading}
                              placeholder={t('checkout:form.step1.fields.phone.placeholder')}
                              prefix={phonePrefix}
                              onFocus={() => setStep(0)}
                              onInput={() => validateStep(0)}
                            />
                          )}
                        </Form.Item>
                      </Card>
                    </div>
                  }
                />
                <Step
                  title={t('checkout:form.step2.title')}
                  status={stepStatus[1]}
                  description={
                    <Card className={css.card}>
                      <div>
                        <Spin spinning={shippingLoading} indicator={<Icon type="loading" />}>
                          {!shippingMethods?.length && <span className={css.shippingWarning}>{getDeliveryMessage()}</span>}
                          {shippingMethods?.length > 0 && (
                            <Form.Item label={t('checkout:form.step2.fields.shippingMethod.label')}>
                              {getFieldDecorator('shippingMethod', {
                                rules: [
                                  {
                                    required: true,
                                    message: t('checkout:form.step2.fields.shippingMethod.messages.isRequired'),
                                  },
                                ],
                              })(
                                <Radio.Group
                                  disabled={loading}
                                  size="large"
                                  onChange={(e: RadioChangeEvent) => {
                                    form.setFieldsValue({ shippingMethod: e.target.value }, () => {
                                      validateStep(1);
                                    });
                                  }}
                                >
                                  {shippingMethods &&
                                    shippingMethods.map(method => {
                                      return (
                                        <Radio.Button
                                          key={`${method.type} ${method.method}`}
                                          value={`${method.type} ${method.method}`}
                                          onClick={() => dispatch(setSelectedShippingActionCreator(method))}
                                        >
                                          {getIcon(method.type)}
                                          {`${method.method_text} - ${method.delivery_time} ${t('checkout:days')} - `}&euro;
                                          {`${method.total_price} `}
                                        </Radio.Button>
                                      );
                                    })}
                                </Radio.Group>
                              )}
                            </Form.Item>
                          )}
                        </Spin>
                      </div>
                    </Card>
                  }
                />
                {/* <Step
                  title={t('checkout:form.step3.title')}
                  status={stepStatus[2]}
                  description={
                    <Card className={css.card}>
                      <div>
                        <Form.Item label={t('checkout:form.step3.fields.paymentMethod.label')}>
                          {getFieldDecorator('paymentMethod', {
                            initialValue: payOnline.name,
                            rules: [
                              {
                                required: true,
                                message: t('checkout:form.step3.fields.paymentMethod.messages.isRequired'),
                              },
                            ],
                          })(
                            <Radio.Group
                              disabled={loading}
                              size="large"
                              onChange={(e: RadioChangeEvent) => {
                                form.setFieldsValue({ paymentMethod: e.target.value }, () => {
                                  validateStep(2);
                                });
                              }}
                            >
                              <Radio.Button key={payOnline._id} value={payOnline.name}>
                                {payOnline.icon && <Icon type={payOnline.icon} className={css.icon} />}
                                {t(`checkout:payment.${payOnline.name}`)}
                              </Radio.Button>
                            </Radio.Group>
                          )}
                        </Form.Item>
                      </div>
                    </Card>
                  }
                /> */}
              </Steps>
            </Form>
          </Spin>
        );
      }}
    </Location>
  );
};

interface MapStateToPropsProps {
  cart: CartItem[];
  manager?: string;
  shippingSelected?: ShippingMethod;
}

const mapStateToProps = (state: RootModel) => ({
  cart: state.cart.cart,
  manager: state.order.manager,
  shippingSelected: state.shipping.selected,
});

export const CheckoutForm = connect(mapStateToProps, { SaveOrderFieldsActionCreator })(
  Form.create<FormComponentProps & MapStateToPropsProps & SaveOrderFieldsAction>({
    onFieldsChange(props, _, values) {
      const orderFieldsToSave: Partial<Order> = {
        user: {
          firstName: values.firstName.value,
          lastName: values.lastName.value,
          phone: values.phone.value,
          email: values.email.value,
          country: values.country.value,
          countryCode: ISOCountries.getAlpha2Code(values.country.value, i18n.language),
          city: values.city.value?.label ? values.city.value.label : values.city,
          kladrId: values.city.value?.key,
          address: values.address.value,
          postalCode: values.postalCode.value,
        },
        products: props.cart,
        shippingMethod: props.shippingSelected as ShippingMethod,
        paymentMethod: { name: i18n.t(`checkout:payment.${payOnline.name}`), _id: payOnline._id, icon: payOnline.icon },
        manager: props.manager,
      };
      props.SaveOrderFieldsActionCreator(orderFieldsToSave);
    },
  })(CheckoutFormComponent)
);
